import React from "react";
import { graphql } from "gatsby";

import Layout from "../Layout/Layout";
import SEO from "../Layout/Seo";
import renderHTML from "../helpers/renderHTML";

class PageSinglecasestudyTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PopupOpened: false
    };
    this.togglePopup = this.togglePopup.bind(this);
  }

  togglePopup() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ PopupOpened: !this.state.PopupOpened });
  }

  render() {
    const casestudy = this.props.data.wordpressWpCaseStudy;
    let count = 0;
    return (
      <Layout>
        <SEO
          title={casestudy.yoast.title ? casestudy.yoast.title : casestudy.title}
          keywords={casestudy.yoast.metakeywords}
          description={casestudy.yoast.metadesc}
        />
        <div className={`case-study-wrapper ${casestudy.slug}`}>
          <section
            className="head"
            style={{
              backgroundImage: `url(${casestudy.acf.main_header_image &&
                casestudy.acf.main_header_image.localFile.childImageSharp.original.src})`
            }}
          >
            <div className="head__overlay" />
            <h1
              className="section__title--large head__title"
              dangerouslySetInnerHTML={renderHTML(casestudy.title)}
            />
          </section>
          <section className="header container">
            <h2
              className="header__title"
              dangerouslySetInnerHTML={renderHTML(casestudy.acf.service_main_header)}
            />
            <div
              className="header__description"
              dangerouslySetInnerHTML={renderHTML(casestudy.acf.service_main_header_description)}
            />
          </section>
          <section className="imagecontent container">
            <div className="services__header">
              <div className="section__title--small">
                {casestudy.acf.content_with_image_title_additional}
              </div>
              <h2 className="section__title--large">{casestudy.acf.content_with_image_title}</h2>
            </div>
            <div className="imagecontent__description">
              <div className="imagecontent__text">
                <div dangerouslySetInnerHTML={renderHTML(casestudy.acf.content_with_image_text)} />
              </div>
              <div
                style={{
                  backgroundImage: `url(${casestudy.acf.content_with_image_photo.localFile.childImageSharp.original.src})`
                }}
                alt="Image for Magento Development"
                className="imagecontent__image"
              />
            </div>
          </section>

          <section className="solution container">
            <div className="services__header">
              <div className="section__title--small">Perfect</div>
              <h2 className="section__title--large">Solution</h2>
            </div>
            {casestudy.acf.is_solution_head_content_enabled === true ? (
              <div className="solution__content">
                <p>{casestudy.acf.solution_head_content}</p>
              </div>
            ) : (
              <></>
            )}
            <div className="solution__steps">
              <div className="solution__step">
                <div className="solution__count">
                  <div className="step__number">01</div>
                  <div className="step__description">Step</div>
                </div>
                <div className="solution__step--content">{casestudy.acf.solution_step_first}</div>
              </div>
              <div className="solution__step">
                <div className="solution__count">
                  <div className="step__number">02</div>
                  <div className="step__description">Step</div>
                </div>
                <div className="solution__step--content">{casestudy.acf.solution_step_second}</div>
              </div>
              <div className="solution__step">
                <div className="solution__count">
                  <div className="step__number">03</div>
                  <div className="step__description">Step</div>
                </div>
                <div className="solution__step--content">{casestudy.acf.solution_step_third}</div>
              </div>
            </div>
            <div className="solution__images">
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                src={
                  casestudy.acf.solution_image_first &&
                  casestudy.acf.solution_image_first.localFile.childImageSharp.original.src
                }
                alt="Solution First Image"
                className="solution__image"
              />
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              {casestudy.acf.solution_image_second !== null ? (
                <img
                  src={
                    casestudy.acf.solution_image_second &&
                    casestudy.acf.solution_image_second.localFile.childImageSharp.original.src
                  }
                  alt="Solution Second"
                  className="solution__image"
                />
              ) : (
                <></>
              )}
            </div>
            <div className="solution__textcontent">
              <div
                className="solution__text"
                dangerouslySetInnerHTML={renderHTML(casestudy.acf.solution_text_first)}
              />
              <div
                className="solution__text"
                dangerouslySetInnerHTML={renderHTML(casestudy.acf.solution_text_second)}
              />
            </div>
          </section>
          {casestudy.acf.is_logos_section_enabled === true ? (
            <section className="logos container logos--first">
              {casestudy.acf.logos_first.map(logo => {
                return (
                  <img
                    src={logo.logo_item && logo.logo_item.localFile.childImageSharp.original.src}
                    alt="Logo"
                  />
                );
              })}
            </section>
          ) : (
            <></>
          )}
          {casestudy.acf.is_integration_section_enabled === true ? (
            <section className="integrations container">
              <h3>Integrations</h3>
              <div className="integrations__logos">
                {casestudy.acf.integration_logos.map(logo => {
                  return (
                    <img
                      src={logo.logo_item && logo.logo_item.localFile.childImageSharp.original.src}
                      alt="Logo"
                    />
                  );
                })}
              </div>
            </section>
          ) : (
            <></>
          )}
          {casestudy.acf.is_youtube_section_enabled === true ? (
            <section className="youtube container">
              <div className="youtube__image">
                <img
                  src={
                    casestudy.acf.youtube_image &&
                    casestudy.acf.youtube_image.localFile.childImageSharp.original.src
                  }
                  alt="Youtube"
                />
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                <div
                  className="image__play"
                  onClick={() => {
                    this.togglePopup();
                  }}
                >
                  ▶
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
          <section className="header benefits container">
            <h2 className="header__title">Outcome / benefits</h2>
            <div className="header__description">
              <p dangerouslySetInnerHTML={renderHTML(casestudy.acf.benefits_text_first)} />
              <div className="benefits__container">
                <div
                  className={`benefits__box benefits__box--${casestudy.acf.benefits_icon_first}`}
                >
                  <span className="benefits__text--small">
                    {casestudy.acf.benefits_highlight_text_first}
                  </span>
                  <span className="benefits__text--large">
                    {casestudy.acf.benefits_highlight_score_first}
                  </span>
                </div>
                <div
                  className={`benefits__box benefits__box--${casestudy.acf.benefits_icon_second}`}
                >
                  <span className="benefits__text--large">
                    {casestudy.acf.benefits_highlight_score_second}
                  </span>
                  <span className="benefits__text--small">
                    {casestudy.acf.benefits_highlight_text_second}
                  </span>
                </div>
                <div
                  className={`benefits__box benefits__box--${casestudy.acf.benefits_icon_third}`}
                >
                  <span className="benefits__text--small">
                    {casestudy.acf.benefits_highlight_text_third}
                  </span>
                  <span className="benefits__text--large">
                    {casestudy.acf.benefits_highlight_score_third}
                  </span>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={renderHTML(casestudy.acf.benefits_text_second)}
                className="benefits__text--additional"
              />
            </div>
          </section>
          <section className="reference container">
            <div className="reference-slide">
              <div className="reference-slide__wrapper">
                <div className="top-corner" />
                <div className="bottom-corner" />
                <div className="client__logo__wrapper " />
                <div className="reference__content">
                  <div className="client__wrapper">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    {casestudy.acf.reference_images.map(image => {
                      count += 1;
                      return (
                        <img
                          className={`client__image lazyload client__image-${count}`}
                          src={
                            image.reference_image &&
                            image.reference_image.localFile.childImageSharp.original.src
                          }
                          alt="Person"
                        />
                      );
                    })}
                  </div>
                  <div
                    className="reference__content--top"
                    dangerouslySetInnerHTML={renderHTML(casestudy.acf.reference_content)}
                  />
                  <div className="reference__content--bottom">
                    <div className="reference__person">
                      <div
                        className="client__name"
                        dangerouslySetInnerHTML={renderHTML(casestudy.acf.reference_title)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="case-studies">
            <div className="services__header">
              <div className="section__title--small">See another</div>
              <h2 className="section__title--large">Case Studies</h2>
            </div>
            <div className="case-studies__content">
              {casestudy.acf.case_studies.map(content => {
                return (
                  <a href={content.case_study_link} className="case-studies__single">
                    <div
                      className="case-studies__inner"
                      style={{
                        backgroundImage: `url(${content.case_study_image.localFile.childImageSharp.original.src})`
                      }}
                    />
                    <div className="case-studies__description">
                      <h3>{content.case_study_title}</h3>
                      <p className="case-studies__text">{content.case_study_description}</p>
                      <p>{content.case_study_hashtags}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </section>

          <section className="main-cta case-study-cta">
            <div className="container">
              <div className="section__header">
                <div className="section__title section__title--contact">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <b>Let's talk about your Magento project!</b>
                </div>
              </div>
              <div className="section__button">
                <div className="cta__content cta__content--what-we-can-do">
                  <div className="join-us-button">
                    <a href="/contact">
                      <div className="btn__cta btn__cta--green btn__cta--contactus">Contact us</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            id="zoombox"
            className={`photo-popup zoombox ${this.state.PopupOpened ? "show" : "hide"}`}
          >
            <iframe
              style={{
                width: "100%",
                height: "100%"
              }}
              title="Youtube"
              src={casestudy.acf.youtube_link}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
          <div
            className={`zoombox-overlay ${this.state.PopupOpened ? "show" : "hide"}`}
            onClick={this.togglePopup}
          />
        </div>
      </Layout>
    );
  }
}

export default PageSinglecasestudyTemplate;

export const pageQuery = graphql`
  query currentcasestudyPageQuery($id: String!) {
    wordpressWpCaseStudy(id: { eq: $id }) {
      path
      slug
      acf {
        service_main_header
        service_main_header_description
        wordpress_
        main_header_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        content_with_image_title
        content_with_image_title_additional
        content_with_image_text
        content_with_image_photo {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        is_solution_head_content_enabled
        solution_head_content
        solution_step_first
        solution_step_second
        solution_step_third
        solution_text_first
        solution_text_second
        solution_image_first {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        solution_image_second {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        logos_first {
          logo_item {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        integration_logos {
          logo_item {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        youtube_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        youtube_link
        benefits_text_first
        benefits_text_second

        reference_content
        reference_title
        reference_images {
          reference_image {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }

        is_youtube_section_enabled
        is_logos_section_enabled
        is_integration_section_enabled
        benefits_icon_first
        benefits_icon_third
        benefits_icon_second
        benefits_highlight_text_first
        benefits_highlight_text_second
        benefits_highlight_text_third
        benefits_highlight_score_first
        benefits_highlight_score_second
        benefits_highlight_score_third

        case_studies {
          case_study_description
          case_study_hashtags
          case_study_link
          case_study_title
          case_study_image {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
      title
      yoast {
        title
        metakeywords
        metadesc
      }
      wordpress_id
    }
  }
`;
